var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "auth",
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("span", { staticClass: "auth__title" }, [_vm._v("Вход")]),
          _c(
            "el-form",
            {
              ref: "authForm",
              staticClass: "authForm",
              attrs: { model: _vm.authForm, "validate-on-rule-change": false },
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "login",
                  staticClass: "item",
                  attrs: { prop: "login", rules: _vm.authRules.login },
                  scopedSlots: _vm._u([
                    {
                      key: "error",
                      fn: function ({ error }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "customErrors" },
                            [
                              _c("ValidationErrorIcon"),
                              _c("div", { staticClass: "customErrors__text" }, [
                                _vm._v(_vm._s(error)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("SimpleInput", {
                    ref: "input",
                    attrs: { placeholder: "Логин" },
                    on: {
                      focus: function ($event) {
                        return _vm.resetField("login")
                      },
                    },
                    model: {
                      value: _vm.authForm.login,
                      callback: function ($$v) {
                        _vm.$set(_vm.authForm, "login", $$v)
                      },
                      expression: "authForm.login",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  ref: "password",
                  staticClass: "item",
                  attrs: { prop: "password", rules: _vm.authRules.password },
                  scopedSlots: _vm._u([
                    {
                      key: "error",
                      fn: function ({ error }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "customErrors" },
                            [
                              _c("ValidationErrorIcon"),
                              _c("div", { staticClass: "customErrors__text" }, [
                                _vm._v(_vm._s(error)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("SimpleInput", {
                    ref: "input",
                    attrs: { type: "password", placeholder: "Пароль" },
                    on: {
                      focus: function ($event) {
                        return _vm.resetField("password")
                      },
                    },
                    model: {
                      value: _vm.authForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.authForm, "password", $$v)
                      },
                      expression: "authForm.password",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "auth__confirm" },
        [
          _c("MainButton", {
            staticClass: "button",
            attrs: { title: "Войти" },
            on: {
              "button-clicked": function ($event) {
                return _vm.submit("authForm")
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }