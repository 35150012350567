<template>
  <section v-loading="loading" class="auth">
    <div class="container">
      <span class="auth__title">Вход</span>

      <el-form
        ref="authForm"
        class="authForm"
        :model="authForm"
        :validate-on-rule-change="false"
      >
        <el-form-item
          ref="login"
          class="item"
          prop="login"
          :rules="authRules.login"
        >
          <SimpleInput
            ref="input"
            v-model="authForm.login"
            placeholder="Логин"
            @focus="resetField('login')"
          />
          <template #error="{ error }">
            <div class="customErrors">
              <ValidationErrorIcon />
              <div class="customErrors__text">{{ error }}</div>
            </div>
          </template>
        </el-form-item>

        <el-form-item
          ref="password"
          class="item"
          prop="password"
          :rules="authRules.password"
        >
          <SimpleInput
            ref="input"
            v-model="authForm.password"
            type="password"
            placeholder="Пароль"
            @focus="resetField('password')"
          />
          <template #error="{ error }">
            <div class="customErrors">
              <ValidationErrorIcon />
              <div class="customErrors__text">{{ error }}</div>
            </div>
          </template>
        </el-form-item>
      </el-form>
    </div>
    <div class="auth__confirm">
      <MainButton
        class="button"
        title="Войти"
        @button-clicked="submit('authForm')"
      />
    </div>
  </section>
</template>

<script>
import MainButton from '../components/UI/buttons/MainButton.vue'
import SimpleInput from '../components/UI/controls/SimpleInput.vue'
import ValidationErrorIcon from '@/assets/icons/ValidationErrorIcon.vue'

import {
  SET_DRAWERS_CLEAN,
  SET_LOGIN_PARAMS,
  SET_TERMINAL,
} from '@/store/actions'
import { SET_PROFILE } from '@/views/profile/store/actions'
import { mapMutations } from 'vuex'
import { setLoginParams } from '@/core'
import API from '@/api'
export default {
  name: 'AuthView',
  components: {
    SimpleInput,
    MainButton,
    ValidationErrorIcon,
  },
  data() {
    return {
      authRules: {
        login: [
          { required: true, message: 'Логин обязательный', trigger: 'blur' },
          {
            min: 4,
            message: 'Логин слишком короткий',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: 'Пароль обязательный', trigger: 'blur' },
          {
            min: 4,
            max: 18,
            message: 'Пароль слишком короткий',
            trigger: 'blur',
          },
          {
            required: true,
            validator: async (rule, value, callback) => {
              if (await this.tryLogin()) {
                callback(new Error('Неверный логин или пароль'))
              }

              return callback()
            },
          },
        ],
      },
      authForm: {
        login: '',
        password: '',
      },
      currentInput: '',
      loading: false,
    }
  },
  methods: {
    ...mapMutations({
      setTerminal: SET_TERMINAL,
      setProfile: SET_PROFILE,
      setParams: SET_LOGIN_PARAMS,
      setDrawers: SET_DRAWERS_CLEAN,
    }),
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$router.push({ name: 'terminal-carNumber' })
        }
      })
    },
    resetField(fieldName) {
      this.$refs[fieldName].clearValidate()
      this.currentInput = fieldName
    },
    onChange(input) {
      this.authForm[this.currentInput] = input
    },
    onInputChange(input) {
      this.authForm[this.currentInput] = input.target.value
    },
    async tryLogin() {
      this.loading = true
      try {
        const { data } = await API.auth.auth(this.authForm)
        const { token, role, default_unload, profile } = data

        setLoginParams(token, role, default_unload, profile)
        this.setTerminal(default_unload, { root: true })
        this.setProfile(profile)
        this.setParams([token, role])
        this.setDrawers(null, { root: true })

        return false
      } catch (e) {
        return true
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/styles/terminalUI/terminalUI.sass'
.hg-theme-default
  background-color: inherit
.el-form-item
  margin: 0
.auth
  background-color: $color-bgd-gray
  padding-top: 76px
  display: grid
  grid-template: auto 300px 1fr / 1fr
  &__title
    display: block
    font-weight: 500
    font-size: 38px
    font-family: $fontPrimary
    margin-bottom: 50px
  .authForm
    display: flex
    align-items: center
    justify-content: space-between
    gap: 20px
    margin-bottom: 69px
    .item
      position: relative
      width: 40%
      border-radius: 30px
      &.is-error
        border: 1px solid $color-red-primary

      .customErrors
        position: absolute
        display: flex
        align-items: center
        column-gap: 5px
        &__text
          font-family: $fontSecondary
          color: $color-red-primary
    .button
      margin-left: 40px
  &__confirm
    display: flex
    justify-content: center
</style>
